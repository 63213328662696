import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDWjb2gQ7UUeLLX1laUEmbF2fXIj25Rlko",
  authDomain: "dawdle-a0433.firebaseapp.com",
  projectId: "dawdle-a0433",
  storageBucket: "dawdle-a0433.appspot.com",
  messagingSenderId: "983473932772",
  appId: "1:983473932772:web:15a5589f09b5b6fbdddff1",
  measurementId: "G-Q0E8HNDRDW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth();
export const provider = new GoogleAuthProvider();
export default app;