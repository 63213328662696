import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../Card';
import axios from 'axios';
import Loader from '../Loader';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 0px;
  gap: 60px;
  margin-bottom: 120px;
`;

export default function Home({ type, openSignInModal }) {
  const [cat, setCat] = useState('random');
  const [bookmarks, setBookmarks] = useState([]);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const user = token || 'null';
      if (user !== 'null') {
        const userRes = await axios.get(`${baseUrl}/users/details`, { headers: { Authorization: token } });
        const bookmarksVideos = userRes.data.data.bookmarks;
        setBookmarks(bookmarksVideos);
      }

      const res = await axios.get(`${baseUrl}/videos/${type}`);
      setVideos(res.data);
      setCat(localStorage.getItem('category') || 'random');
      setLoading(false);
    };

    fetchData();
  }, [type, token, baseUrl]);

  const filteredVideos = cat === 'random' ? videos : videos.filter((video) => video.category === cat);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {filteredVideos.map((video) => (
            <Card
              type={type}
              key={video._id}
              video={video}
              isBookmarked={bookmarks.includes(video._id) || false}
              openSignInModal={openSignInModal} // Pass openSignInModal function
            />
          ))}
        </Container>
      )}
    </>
  );
}
