import { XlviLoader } from "react-awesome-loaders";
import { Box } from "@mui/material";

const Loader = () => {
  return (
    <Box
      sx={{
        width: "100vw",  // Ensure the container is full viewport width
        height: "100vh", // Ensure the container is full viewport height
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute", // Make sure it is not influenced by other layout styles
        top: 0,
        left: 0,
        backgroundColor: "rgba(255, 255, 255, 0.7)", // Optional: adds a slight background color
        zIndex: 1000, // Ensure it appears above other content
      }}
    >
      <XlviLoader
        boxColors={["#EF4444", "#F59E0B", "#6366F1"]}
        desktopSize={"64px"}
        mobileSize={"50px"}
      />
    </Box>
  );
};

export default Loader;
